/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    crosshair2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 0a.5.5 0 01.5.5v.518A7 7 0 0114.982 7.5h.518a.5.5 0 010 1h-.518A7 7 0 018.5 14.982v.518a.5.5 0 01-1 0v-.518A7 7 0 011.018 8.5H.5a.5.5 0 010-1h.518A7 7 0 017.5 1.018V.5A.5.5 0 018 0m-.5 2.02A6 6 0 002.02 7.5h1.005A5 5 0 017.5 3.025zm1 1.005A5 5 0 0112.975 7.5h1.005A6 6 0 008.5 2.02zM12.975 8.5A5 5 0 018.5 12.975v1.005a6 6 0 005.48-5.48zM7.5 12.975A5 5 0 013.025 8.5H2.02a6 6 0 005.48 5.48zM10 8a2 2 0 10-4 0 2 2 0 004 0"/>',
    },
});
